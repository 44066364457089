import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  const model = fb.group({
    modelNumber: [
      data.modelNumber || "",
      [],
      { label: "Product Model Number", type: "text" },
    ],
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea" },
    ],
    // unsubscribed : [false, [], { label: `Subscribe to our newsletter`, type: 'checkbox' }],
  });

  return model;
}
