import React from "react";
import env from "../../env";
import { SiteLink } from "../../components";
import { usePost } from "../../components";
import { ErrorPopup, Loading } from "../../components";
import { useRouter } from "../../components";
import { Tile } from "../product/productTile";
import { useBV } from "../bazzarVoice/useBV";

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  useBV(null, { showReview: null });

  const keyword = query.q;

  React.useEffect(() => {
    post.send(env.apiBase + "/api/search/search", { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  const data = post.response;

  // products
  const products = data.products;
  const productsOn = products.filter(function (obj) {
    return obj.productStatus !== 98;
  });
  // const sortedProductsOn = productsOn.sort((a, b) => b.price - a.price);
  const sortedProductsOn = productsOn;

  // pages
  const pages = data.pages;
  function removeDuplicates(arr) {
    const uniqueArray = [];
    for (const item of arr) {
      const existingItem = uniqueArray.find(
        (uniqueItem) => uniqueItem.pageUrl === item.pageUrl
      );
      if (!existingItem) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }
  const sortedPages = removeDuplicates(pages);

  if (sortedProductsOn.length === 0 && sortedPages.length === 0)
    return (
      <div className='alert alert-danger' role='alert'>
        No result found.
      </div>
    );
  return (
    <>
      {sortedProductsOn.length > 0 && (
        <div>
          <h3>
            Products related to{" "}
            <span style={{ fontWeight: "700" }}>{keyword}</span>
          </h3>
          <ProductList products={sortedProductsOn} />
        </div>
      )}
      {sortedPages.length > 0 && (
        <div>
          <h3 style={{ marginTop: "2rem" }}>
            Articles related to{" "}
            <span style={{ fontWeight: "700" }}>{keyword}</span>
          </h3>
          <PageList pages={sortedPages} />
        </div>
      )}
    </>
  );
}

function ProductList({ products }) {
  return (
    <div className='productList'>
      {products.map((product, index) => (
        <Tile product={product} key={product.productCode}></Tile>
      ))}
    </div>
  );
}

function PageList({ pages }) {
  return (
    <div className='pageList row'>
      {pages.map((page, index) => (
        <div className='pageListItem col-md-6 col-lg-4' key={index}>
          <div className='pageListItem__inner'>
            <SiteLink className='pageListItem__title' to={page.pageUrl}>
              {page.imageUrl ? (
                <img
                  src={page.imageUrl}
                  alt='page_image'
                  className='page_image'
                />
              ) : (
                <img
                  src='/assets/logo-white-background.png'
                  alt='page_image'
                  className='page_image'
                />
              )}
              {page.pageTitle}
            </SiteLink>
            <div
              className='pageListItem__highlight'
              dangerouslySetInnerHTML={{ __html: page.searchHighlight }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}
